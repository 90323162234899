import React from 'react';
import styled from 'styled-components';
import SponsorPyramid from './SponsorPyramid';

const FooterContainer = styled.footer`
  text-align: center;
  padding: 1rem;
  border-top: solid;
  border-width: 4px;
  border-color: ${(p) => p.theme.color.boldText};
`;

function Footer() {
  return (
    <FooterContainer>
      <SponsorPyramid />
      &copy; Radiodiodi 2025
    </FooterContainer>
  );
}

export default Footer;
