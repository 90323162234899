import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

/* const phrases = [
  "Korkea taajuus, matala laatu",
  "Matala taajuus, korkea laatu",
  "Etelän halvinta puheaikaa",
  "Suomen nopein kuulotelevisio",
  "Ovi kiinni, Radio auki",
  "Oispa konteilla",
  "Mitäs tyhmää vois vielä keksiä...",
  "Ei mitään kakkaa vaan semi-laatua",
  "Buy high, sell low",
  "Älykkäät ihmiset, tyhmät järjestelmät",
  "Ai onks tää päällä huppista",
  "Se on rock!",
  "Testattu tuotannossa! :D",
  "Testataan tuotannossa! :P",
  "Sirkus pyörii, pellet vaihtuu",
  "Maistuu hyvältä kumpaankin suuntaan"
  //<>Tee ite paremmin:<a href="mailto:rekry@radiodiodi.fi">{' '}rekry@radiodiodi.fi</a></>
]; */

const Phrase = styled.div`
  min-height: 2.5em;
  margin: 0 0.3em 0;

  font-size: 1.3rem;
  line-height: 1em;
  color: ${(p) => p.theme.color.boldText};

  text-align: center;

  @media screen and (min-width: 499px) {
    max-width: 40%;
  }
`;

const Slogan = () => {
  const { t } = useTranslation();

  const numberOfPhrases = Number(t('navbar.numPhrases'));
  const index = useMemo(
    () => Math.floor(numberOfPhrases * Math.random()),
    [numberOfPhrases]
  );

  return <Phrase>{t(`navbar.phrases.${index}`)}</Phrase>;
};

export default Slogan;
