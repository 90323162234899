import React, { useState } from 'react';
// import ReactLink from '../../common/Link';
import styled from 'styled-components';

import ImageGallery from '../../common/ImageGallery';
// import Instagram from '../../common/Instagram';
// import Calendar from '../../calendar/Calendar';
// import Shoutbox from '../../common/Shoutbox';
import { linkToTelegramChat } from '../../../utils';
import Countdown from '../../common/Countdown';
import VideoPlayer from '../../common/VideoPlayer';
// import { signupCloses, signupOpens, postSignupCloses, postSignupOpens } from '../registration/Registration';
import { useTranslation, Trans } from 'react-i18next';

const Container = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  background-color: ${(p) => p.theme.color.containerBg};
  padding: 1rem;
  margin-top: 1rem;
  padding-top: 0;

  @media screen and (max-width: 800px) {
    flex-direction: column-reverse;
    flex-direction: column;
  }

  & > * {
    /*flex: 0 1 50%;*/
  }
`;

const Title = styled.h1`
  margin: 0 0 1rem;
`;

const Paragraph = styled.div`
  margin-bottom: 1rem;
`;

const Trivia = styled.ul`
  li {
    margin-top: 1rem;
  }

  li::marker {
    color: ${(p) => p.theme.color.boldText};
    font-size: 1.3em;
  }

  strong {
    color: ${(p) => p.theme.color.boldText};
  }
`;

/* const Announcement = styled.div`
  padding: 2rem;
  background-color: ${p => p.theme.color.firstBoxBg};
  color: ${p => p.theme.color.firstBoxText};
  font-size: 1.6rem;
  line-height: 1.1em;
  font-weight: bold;
  text-align: center;

  a {
    color: ${p => p.theme.color.boldText};
  }

  hr {
    width: 10%;
    border-style: dashed;
  }

  @media screen and (max-width: 360px) {
    font-size: 1.2rem;
    padding: 1rem;
  }
`; */

/* const Recruitment = styled.div`
   padding: 2rem;
   background-color: ${p => p.theme.color.firstBoxBg};
   color: ${p => p.theme.color.firstBoxText};
   font-size: 0.8rem;
   line-height: 0.5rem;
   font-weight: bold;
   text-align: center;
   font-style: italic;

   hr {
    width: 10%;
    border-style: dashed;
   }
`; */

/* const Link = styled(ReactLink)`
   color: ${p => p.color || p.theme.color.linkColor};
   transition: color 0.2s ease;

   &:hover, &:focus {
     color: ${p => p.theme.color.linkColorHover};
     text-decoration: none;
   }
 `; */

// const CenteredParagraph = styled.section`
//   margin: 0 auto 3rem;
//   font-size: 1.2rem;
//   max-width: 80ch;
//   text-align: justify;
// `;

const countdownTo = new Date('2024-04-15T12:00:00+03:00');

const Frontpage = () => {
  const { t } = useTranslation();
  // don't disappear countdown suddenly
  const [showCountdown] = useState(new Date() < countdownTo);
  return (
    <>
      {showCountdown && (
        <Countdown title={t('frontpage.countdown.title')} to={countdownTo} />
      )}
      {/* <Announcement>
        {new Date() >= postSignupCloses
          ? <Trans
              i18nKey="frontpage.announcement.thankYou"
              components={[<Link to="/rekry">Signup link</Link>]}
            />
          : new Date() >= postSignupOpens
          ? <Trans 
              i18nKey="frontpage.announcement.freeSlots"
              components={[<Link to="/ilmo" />]} 
            />
          : new Date() >= signupCloses
          ? <>{t("frontpage.announcement.postregistration")}</>
          : new Date() >= signupOpens
          ? <Trans 
              i18nKey="frontpage.announcement.registration"
              components={[<Link to="/ilmo" />]} 
            />
          : <>{t("frontpage.announcement.preregistration")}</>
        }
      </Announcement> */}
      {/* <Recruitment>
        <Link to="/ilmo">Mukaan toimittamaan Radiodiodia?</Link>
      </Recruitment> */}

      <Container>
        <div>
          <Paragraph>
            <Title>{t('frontpage.title')}</Title>
            <div>
              {t('frontpage.description')} &ndash;{' '}
              <a href="mailto:rekry@radiodiodi.fi">rekry@radiodiodi.fi</a>
            </div>
            <br />
            <div>
              <Trans
                i18nKey="frontpage.moreInfo"
                components={[
                  <a
                    title="Telegram-tiedotus"
                    href={linkToTelegramChat('radiodiodi')}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                  </a>,
                  <a
                    title="Telegram-keskustelu"
                    href={linkToTelegramChat('radiodiodichat')}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                  </a>,
                ]}
              />
            </div>
          </Paragraph>
          <Title>{t('frontpage.didYouKnow.title')}</Title>
          <Trivia>
            <li>
              <Trans
                i18nKey="frontpage.didYouKnow.1"
                components={[
                  <a href="https://www.ayy.fi/fi/hugo-gaala-palkitsi-aalto-yhteison-pioneereja">
                    {' '}
                  </a>,
                ]}
              />
            </li>
            <li>
              <Trans
                i18nKey="frontpage.didYouKnow.2"
                components={[<strong>141</strong>]}
              />
            </li>
            <li>
              <Trans
                i18nKey="frontpage.didYouKnow.3"
                components={[<strong>61</strong>]}
              />
            </li>
            <li>
              <Trans i18nKey="frontpage.didYouKnow.4" />
            </li>
          </Trivia>
          <Title>{t('frontpage.contactUs.title')}</Title>
          <strong>{t('frontpage.contactUs.editorialTeam')}: </strong>
          <a href="mailto:toimitus@radiodiodi.fi">toimitus@radiodiodi.fi</a>
          <br />
          <strong>{t('frontpage.contactUs.corporatePartnerships')}: </strong>
          <a href="mailto:yrityssuhteet@radiodiodi.fi">
            yrityssuhteet@radiodiodi.fi
          </a>
        </div>
      </Container>
      {/* <Shoutbox /> */}
      {/* <Calendar /> */}
      {/* <Title>{t('frontpage.editorialTeam.title')}</Title> */}
      <ImageGallery />
      {/* <Instagram /> */}
      <VideoPlayer src="https://www.youtube-nocookie.com/embed/uublsUz-7Bw" />
    </>
  );
};

export default Frontpage;
