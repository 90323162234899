import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import useSWR from 'swr';
import styled from 'styled-components';
import { environment } from '../../../utils';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  p {
    max-width: 700px;
  }
`;

const Results = styled.div``;

const Result = styled.div`
  color: ${(p) => (p.header ? p.theme.color.boldText : p.theme.color.bodyText)};
  font-weight: ${(p) => (p.header ? 'bold' : 'inherit')};
  background-color: ${(p) =>
    p.contrast ? p.theme.color.white10 : p.theme.color.white30};
  padding: 0.5rem 0.3rem;
  margin: 0.2rem 0;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 500px) {
    flex-direction: column;

    div:not(:first-child) {
      margin-top: 0.5rem;
    }
  }
`;

const Error = styled.h2`
  text-align: center;
  margin: 1rem auto;
  font-weight: 400;
  color: ${(p) => p.theme.color.boldText};
`;

const Column = styled.div`
  flex: 1;
  margin-left: 0.5rem;
  line-height: 1.2;
`;

const SearchBar = styled.input`
  padding: 0.5rem;
  flex: 1;
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 2rem;
`;

const Button = styled.button`
  background: none;
  background-color: ${(p) => p.theme.color.boldText};
  border: none;
  cursor: pointer;
  font-weight: 700;
  color: white;
  padding: 1em 2em;
  letter-spacing: 0.05rem;

  &:hover,
  &:active {
    background-color: ${(p) => p.theme.color.redMedium};
  }

  &:disabled {
    background-color: ${(p) => p.theme.color.bodyText};
    cursor: default;
    color: #dbdbdb;
    border: none;
  }
`;

const SearchButton = styled(Button)`
  padding: 0.5rem;
  min-width: 5rem;
  text-align: center;
  cursor: pointer;
`;

const TypePickerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 1rem;
  gap: 0.2rem;
`;

const Tab = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;

  input {
    display: none;
  }

  label {
    padding: 0.5rem 1rem;
    background-color: ${(p) => p.theme.color.white10};
    cursor: pointer;
  }

  label:hover {
    background-color: ${(p) => p.theme.color.redMedium};
  }

  input:checked + label {
    background-color: ${(p) => p.theme.color.boldText};
    color: ${(p) => p.theme.color.white100};
  }
`;

const TypePicker = ({ type, setType }) => {
  const { t } = useTranslation();
  return (
    <TypePickerContainer>
      <Tab>
        <input
          id="title"
          type="radio"
          name="type"
          onChange={() => setType('title')}
          checked={type === 'title'}
        />
        <label for="title">{t('library.song')}</label>
      </Tab>
      <Tab>
        <input
          id="artist"
          type="radio"
          name="type"
          onChange={() => setType('artist')}
          checked={type === 'artist'}
        />
        <label for="artist">{t('library.artist')}</label>
      </Tab>
      <Tab>
        <input
          id="album"
          type="radio"
          name="type"
          onChange={() => setType('album')}
          checked={type === 'album'}
        />
        <label for="album">{t('library.album')}</label>
      </Tab>
    </TypePickerContainer>
  );
};

const Search = ({ onInput, forwardRef }) => {
  const { t } = useTranslation();
  const onPromptKeyPress = (event) => {
    const ENTER_KEY = 13;

    if ([event.keyCode, event.which].includes(ENTER_KEY)) {
      onInput();
    }
  };

  return (
    <SearchContainer>
      <SearchBar onKeyPress={onPromptKeyPress} ref={forwardRef} type="text" />
      <SearchButton onClick={onInput}>{t('library.search')}</SearchButton>
    </SearchContainer>
  );
};

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }

  span {
    margin: 1rem 4rem;
  }
`;

const Pagination = ({ index, setIndex, amountOfRows }) => {
  const { t } = useTranslation();
  const ROWS_PER_PAGE = 50;

  const isPreviousDisabled = index <= 1;
  const isNextDisabled = index * ROWS_PER_PAGE >= amountOfRows;
  if (isPreviousDisabled && isNextDisabled) {
    return null;
  }
  return (
    <PaginationContainer>
      <Button disabled={isPreviousDisabled} onClick={() => setIndex(index - 1)}>
        {t('library.previous')}
      </Button>
      <span>
        {t('library.page')}: {index}
      </span>
      <Button disabled={isNextDisabled} onClick={() => setIndex(index + 1)}>
        {t('library.next')}
      </Button>
    </PaginationContainer>
  );
};

const BACKEND_URL = environment.REACT_APP_BACKEND_HTTP_URL;
const fetcher = (queryString) =>
  axios
    .get(`${BACKEND_URL}/library/search?${queryString}`)
    .then((res) => res.data);

const createQueryString = (type, query, pageIndex) =>
  `type=${type}&search=${encodeURIComponent(query)}&page=${pageIndex}`;

const swrConfig = {
  dedupingInterval: 864000,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  errorRetryCount: 3,
};

// component used to only prefetch the data on
// the next page so that it's already loaded when
// the user decides to go there
const Prefetch = ({ type, query, pageIndex }) => {
  const queryString = createQueryString(type, query, pageIndex + 1);
  useSWR(queryString, fetcher, swrConfig);

  return null;
};

const Library = () => {
  const [pageIndex, setPageIndex] = useState(1);
  const [type, setType] = useState('title');
  const [query, setQuery] = useState('');
  const inputRef = useRef();

  const queryString = createQueryString(type, query, pageIndex);
  const { data, error } = useSWR(queryString, fetcher, swrConfig);

  const { t } = useTranslation();

  // scroll to top when the data is updated i.e.
  // the user has navigated to other page
  useEffect(() => {
    inputRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [inputRef, data]);

  const renderResult = (result, index) => {
    if (!result || !result.title) return null;

    const artistData = Array.isArray(result.artist)
      ? result.artist.join(', ')
      : result.artist;

    const even = index % 2 === 0;

    return (
      <Result white key={index} contrast={even}>
        <Column>{result.title}</Column>
        <Column>{artistData}</Column>
        <Column>{result.album}</Column>
      </Result>
    );
  };

  const renderResults = (results) => {
    if (!results || !Array.isArray(results)) {
      return null;
    }

    if (results.length === 0) {
      return <Error>{t('library.noResults')}</Error>;
    }

    const rows = results.map((r, i) => renderResult(r, i));
    return (
      <Results>
        <Result header>
          <Column>{t('library.song')}</Column>
          <Column>{t('library.artist')}</Column>
          <Column>{t('library.album')}</Column>
        </Result>
        {rows}
      </Results>
    );
  };

  const onInput = () => {
    setPageIndex(1);
    const query = inputRef.current.value;
    setQuery(query);
  };

  const onTypeSelect = (type) => {
    setType(type);
    onInput();
  };

  return (
    <Container>
      <h1>{t('library.musicLibrary')}</h1>
      <p>{t('library.info')}</p>

      <TypePicker type={type} setType={onTypeSelect} />
      <Search forwardRef={inputRef} onInput={onInput} />

      {!error && !data && <Error>{t('library.fetching')}</Error>}
      {error && <Error>{t('library.error')}</Error>}
      {data && renderResults(data.result)}
      <Prefetch type={type} query={query} pageIndex={pageIndex} />

      <Pagination
        index={pageIndex}
        setIndex={setPageIndex}
        amountOfRows={data ? data.amount : 0}
      />
    </Container>
  );
};

export default Library;
