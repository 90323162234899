import React from 'react';
import styled from 'styled-components';
import Link from '../../common/Link';
import Programme from './Programme';
import { useTranslation, Trans } from 'react-i18next';

const Container = styled.div`
  strong {
    color: ${(p) => p.theme.color.boldText};
  }
`;

const TranslationWithStrong = (key) => {
  return <Trans i18nKey={key} components={[<strong />]} />;
};

const Checklist = () => {
  const { t } = useTranslation();
  return (
    <>
      <Container>
        <h3>{t('checklist.title')}</h3>
        <ol>
          <li>{t('checklist.1')}</li>
          <li>{t('checklist.2')}</li>
          <li>{t('checklist.3')}</li>
          <li>
            <Link to="/ilmo">{t('checklist.4')}</Link>
          </li>
          <li>
            {t('checklist.5')}:{' '}
            <a href="mailto:toimitus@radiodiodi.fi">toimitus@radiodiodi.fi</a>
          </li>
          <li>
            <Trans
              i18nKey="checklist.6"
              components={[<a href="mailto:kuvat@radiodiodi.fi"> </a>]}
            />
          </li>
          <li>{t('checklist.7')}</li>
          <li>{t('checklist.8')}</li>
          <li>{t('checklist.9')}</li>
          {/*<li>Osallistu kaatoon!</li>*/}
        </ol>

        <h3>{t('checklist.planning.title')}</h3>
        <p>{TranslationWithStrong('checklist.planning.name')}</p>
        <p>{TranslationWithStrong('checklist.planning.description')}</p>
        <p>{TranslationWithStrong('checklist.planning.goal')}</p>
        <p>{TranslationWithStrong('checklist.planning.theme')}</p>
        {/* <p><strong>Aikataulu.</strong> Aamuyön ohjelma voi olla hyvinkin erilaista kuin keskipäivän ohjelma.</p> */}
        <p>{TranslationWithStrong('checklist.planning.audience')}</p>
        <p>{TranslationWithStrong('checklist.planning.elements')}</p>
        <p>{TranslationWithStrong('checklist.planning.ads')}</p>
      </Container>
      <Programme />
    </>
  );
};

export default Checklist;
