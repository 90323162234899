import React from 'react';
import styled from 'styled-components';

import Person from './Person';
import { environment } from '../../../utils';

const Gallery = styled.section`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
`;

const IMG_URL = `${environment.REACT_APP_STATIC_URL}/img/2025/toimitus/`;

const people = [
  {
    name: 'Ilkka',
    role: 'frontpage.editorialTeam.editor',
    img: `${IMG_URL}ilkka.jpg`,
    mail: 'paatoimittaja@radiodiodi.fi',
  },
  {
    name: 'Samuli',
    role: 'frontpage.editorialTeam.oltermanni',
    img: `${IMG_URL}samuli.jpg`,
    mail: 'leo@radiodiodi.fi',
  },
  {
    name: 'Aaron',
    role: 'frontpage.editorialTeam.promoRep',
    img: `${IMG_URL}aaron.jpg`,
    mail: '@radiodiodi.fi',
  },
  {
    name: 'Risto',
    role: 'frontpage.editorialTeam.studio',
    img: `${IMG_URL}risto.jpg`,
    mail: 'studio@radiodiodi.fi',
  },
  {
    name: 'Santeri',
    role: 'frontpage.editorialTeam.corporate',
    img: `${IMG_URL}santeri.jpg`,
    mail: 'yrityssuhteet@radiodiodi.fi',
  },
  {
    name: 'Elli',
    role: 'frontpage.editorialTeam.communications',
    img: `${IMG_URL}elli.jpg`,
    mail: 'viestinta@radiodiodi.fi',
  },
  {
    name: 'Anna',
    role: 'frontpage.editorialTeam.graphics',
    img: `${IMG_URL}anna.jpg`,
    // mail: 'anni@radiodiodi.fi',
  },
  {
    name: 'Teemu',
    role: 'frontpage.editorialTeam.construction',
    img: `${IMG_URL}teemu.jpg`,
    // mail: 'vili@radiodiodi.fi',
  },
  {
    name: 'Eemeli',
    role: 'frontpage.editorialTeam.tech',
    img: `${IMG_URL}eemeli.jpg`,
    mail: 'tekniikka@radiodiodi.fi',
  },
  {
    name: 'Akseli',
    role: 'frontpage.editorialTeam.tech',
    img: `${IMG_URL}akseli.jpg`,
    mail: 'tekniikka@radiodiodi.fi',
  },
];

const ImageGallery = () => {
  return (
    <Gallery>
      {people.map((person, index) => (
        <Person key={index} {...person} />
      ))}
    </Gallery>
  );
};

export default ImageGallery;
