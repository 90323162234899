import React, { useState } from 'react';
import styled from 'styled-components';
import CalendarWidget from './CalendarWidget';
import DatePicker from './DatePicker';
import TimePicker from './TimePicker';
import { CrossIcon } from '../Icons';
import { useTranslation } from 'react-i18next';

const PropositionContainer = styled.div`
  padding: 0.5rem 0 0.5rem 1rem;
  margin-bottom: 1rem;
  border-left: 4px solid ${(p) => p.theme.color.boldText};
`;

const PropositionRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;

  @media screen and (min-width: 700px) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
`;

const Column = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
`;

const Label = styled.label`
  font-size: 0.9rem;
`;

const RemoveButton = styled.span`
  display: flex;
  max-width: 30px;
  margin: 0.5rem;
  align-self: flex-end;
  cursor: pointer;

  &:hover svg path {
    fill: ${(p) => p.theme.color.boldText};
  }

  svg {
    width: 25px;
    height: 25px;

    path {
      fill: ${(p) => p.theme.color.boldText};
    }
  }
`;

const Proposition = ({ onUpdateValue, startTime, endTime, date, onDelete }) => {
  const [showCalendar, setShowCalendar] = useState(true);
  const { t } = useTranslation();

  const onDatePickerClick = () => {
    setShowCalendar(!showCalendar);
  };

  const onDateSelect = (dateDay) => {
    const date = `${dateDay}.04.2024`;
    setShowCalendar(false);

    onUpdateValue({ date, startTime, endTime });
  };

  const onStartTimeChange = (event) => {
    const startTime = event.target.value;
    onUpdateValue({ date, startTime, endTime });
  };

  const onEndTimeChange = (event) => {
    const endTime = event.target.value;
    onUpdateValue({ date, startTime, endTime });
  };

  const remove = () => {
    onDelete();
  };

  return (
    <>
      <PropositionContainer>
        <Row>
          <DatePicker
            onClick={onDatePickerClick}
            date={date}
            show={showCalendar}
          />
          <RemoveButton onClick={remove}>
            <CrossIcon />
          </RemoveButton>
        </Row>
        <PropositionRow>
          <CalendarWidget onDateSelect={onDateSelect} show={showCalendar} />
          <Row>
            <Column>
              <Label>{t('signup.propositions.startTime')}</Label>
              <TimePicker onChange={onStartTimeChange} time={startTime} />
            </Column>
            <Column>
              <Label>{t('signup.propositions.endTime')}</Label>
              <TimePicker onChange={onEndTimeChange} time={endTime} />
            </Column>
          </Row>
        </PropositionRow>
      </PropositionContainer>
    </>
  );
};

export default Proposition;
