import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import FadeImage from '../FadeImage';
import { useTranslation } from 'react-i18next';

const Guy = styled.div`
  text-align: center;
  --spacing: 2rem;
  margin: 1rem var(--spacing) var(--spacing);
  width: 100%;

  @media screen and (min-width: 701px) {
    width: calc((100% - var(--spacing) * 4) / 2);
  }

  @media screen and (min-width: 1001px) {
    width: calc((100% - var(--spacing) * 6) / 3);
    margin-top: -3rem;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      margin-top: 1rem;
    }
    &:nth-child(4),
    &:nth-child(9) {
      margin-left: calc(
        (100% - var(--spacing) * 6) / 3 / 2 + var(--spacing) * 2
      );
    }
  }
`;

const PersonHeader = styled.div`
  color: ${(p) => p.theme.color.boldText};
  font-size: 1.2rem;
  font-weight: 700;
`;

const PersonRole = styled.div`
  color: ${(p) => p.theme.color.bodyText};
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.5px;
`;

const Img = styled(FadeImage)`
  margin-top: 1rem;
  max-width: 100%;
  ${(p) => p.css};
  border-radius: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
`;

const Desc = styled.p`
  text-align: right;
  font-style: italic;
`;

const Person = ({ name, role, img, desc }) => {
  const { t } = useTranslation();
  return (
    <Guy>
      <Img src={img} alt="" loading="lazy" />
      <PersonHeader>{name}</PersonHeader>
      <PersonRole>{t(role)}</PersonRole>
      {desc && <Desc>{desc}</Desc>}
    </Guy>
  );
};

Person.propTypes = {
  name: PropTypes.string,
  img: PropTypes.any,
  role: PropTypes.string,
  desc: PropTypes.string,
  mail: PropTypes.string,
};

export default Person;
