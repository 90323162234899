import { createGlobalStyle } from 'styled-components';

export const theme = {
  color: {
    bgBlue: '#1A1830',
    bgDark: 'rgba(33, 33, 33, 1)',
    grey100: 'rgba(30, 29, 50, 1)',
    white100: 'rgba(255, 255, 255, 1.0)',
    white70: 'rgba(255, 255, 255, 0.7)',
    white30: 'rgba(255, 255, 255, 0.3)',
    white10: 'rgba(255, 255, 255, 0.10)',

    // 2025 colors
    bgLight: '#DCF6DC', // Changed
    containerBg: '#FEFFEE', // Changed
    blueDark: '#720078', // Changed
    blueLight: '#ff00ff',
    boldText: '#275328', // Changed
    redMedium: '#FEFFEE', // Changed
    bodyText: '#275328', // Changed
    // firstBoxBg: 'rgba(131, 165, 242, .31)',
    // firstBoxText: '#ff00ff',
    countdownBg: 'rgba(255, 156, 148, .5)',
    linkColor: '#008E04', // Changed
    // linkColorHover: '#FF9C94',
    headingColor: '#275328', // Changed
    headerLinkColor: '#275328', // Changed
    // englishLinkColor: '#275328', //Changed
    galleryHoverBg: '#720078',
    galleryHoverText: 'white',

    // make sure we can see unused colors
    orange100: 'lime',
    orangedark100: 'lime',
    turquoise100: 'lime',
    pink100: 'lime',
    darkpink100: 'lime',
    bgGreen: 'lime',
    lightOrange: 'lime',
    realOrange: 'lime',
    darkOrange: 'lime',
    white2: 'lime',
  },
  font: {
    heading: 'Montserrat, sans-serif', // Similar to Avenir, serif headings.
    body: 'Montserrat, sans-serif', // Similar to Avenir, body text.
  },
};

export const GlobalStyle = createGlobalStyle`
    body {
      font-family: ${theme.font.body};
      font-weight: 500;
      letter-spacing: 0.5px;
      max-width: 1000px;
      margin: 0 auto 0;
      padding: 1rem;
      line-height: 1.5rem;
      overflow-x: hidden;
      position: relative;
    }

    html {
      box-sizing: border-box;
      background-color: ${theme.color.bgLight};
      color: ${theme.color.bodyText};
    }

    *,
    *::before,
    *::after {
      box-sizing: inherit;
    }

    h1,
    h2 {
      line-height: 2rem;
      font-size: 1.5rem;
      font-family: ${theme.font.heading};
      font-weight: bolder;
      color: ${theme.color.headingColor};
      display: flex;
      margin: 0;
      padding: 1.5rem 0 0;
    }

    h3 {
      font-size: 1.5rem;
      color: ${theme.color.headingColor};
    }

    a {
      color: ${theme.color.linkColor};
      text-decoration: none;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    strong {
      font-weight: 700;
    }
`;

export default GlobalStyle;
